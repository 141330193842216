<div class="contact-us-container">
  <div class="heading-3 heading-web">تواصل معنا</div>
  <div class="section-wrapper d-flex w-100">
    <div class="mobile-section">
      <div class="mobile-img">
        <img loading="lazy" [src]="
            '../../../../assets/images/mobile-model' +
            (isMobile ? '-res' : '') +
            '.svg'
          " alt="تواصل معنا" />
        <div class="stores-container d-flex">
          <div class="title">حمل تطبيقنا</div>
          <div class="store" (click)="downloadApp('appStore')">
            <img loading="lazy" src="../../../../assets/images/badge-app-store.svg" alt="App Store تطبيق عزوة على" />
          </div>
          <div class="store" (click)="downloadApp('googlePlay')">
            <img loading="lazy" src="../../../../assets/images/badge-google-play.svg"
              alt="Google play تطبيق عزوة على" />
          </div>
        </div>
      </div>
    </div>
    <div class="content-section">
      <div class="heading-3 heading-res">تواصل معنا</div>
      <div class="social-media d-flex">
        <div class="social-icon" (click)="navigateToPlatforms('whatsApp')">
          <img loading="lazy" src="../../../../assets/images/social-icon/what'sapp.svg" alt="whatsapp" />
        </div>
        <div class="social-icon" (click)="navigateToPlatforms('tiktok')">
          <img loading="lazy" src="../../../../assets/images/social-icon/tiktok.svg" alt="tiktok" />
        </div>
        <div class="social-icon" (click)="navigateToPlatforms('instagram')">
          <img loading="lazy" src="../../../../assets/images/social-icon/instagram.svg" alt="instagram" />
        </div>
        <div class="social-icon" (click)="navigateToPlatforms('x')">
          <img loading="lazy" src="../../../../assets/images/social-icon/x.svg" alt="منصة أكس" />
        </div>
        <div class="social-icon" (click)="navigateToPlatforms('snapchat')">
          <img loading="lazy" src="../../../../assets/images/social-icon/snapchat.svg" alt="snapchat" />
        </div>
        <div class="social-icon" (click)="touchField(firstName)">
          <img loading="lazy" src="../../../../assets/images/social-icon/email.svg" alt="email" />
        </div>
      </div>
      <div class="form-wrapper">
        <form #contactUsForm="ngForm" id="contactUsForm">
          <div class="name-wrapper d-flex">
            <!-- First Name -->
            <div class="field">
              <label class="field__label">
                الاسم الأول
                <span class="field__label__required">*</span>
              </label>
              <mat-form-field appearance="outline">
                <input class="warning-border" matInput ngModel name="firstName" required #firstName="ngModel"
                  id="fullName" pattern="^[a-zA-Z\s\u0600-\u06ff]+$" minlength="1"
                  onkeydown="return /[a-zA-Z\s\u0600-\u06ff]/i.test(event.key)" placeholder="عزوة"
                  [TemplateFormValidator]="firstName" [validator]="validateWhiteSpacesInput" appTrim />
              </mat-form-field>
              @if( !firstName?.valid && firstName.touched && firstName.errors &&
              (firstName.errors['required'] || firstName.errors['validator'])){
              <small class="field__alert-message">هذا الحقل مطلوب</small>
              } @if ( firstName.errors && firstName.touched &&
              firstName.errors['pattern']) {
              <small class="field__alert-message">لا يمكن ادخال حروف مميزة او ارقام</small>
              }
            </div>
            <!-- Second Name -->
            <div class="field">
              <label class="field__label"> الاسم الثاني </label>
              <mat-form-field appearance="outline">
                <input class="warning-border" matInput ngModel name="secondName" #secondName="ngModel" id="fullName"
                  pattern="^[a-zA-Z\s\u0600-\u06ff]+$" onkeydown="return /[a-zA-Z\s\u0600-\u06ff]/i.test(event.key)"
                  minlength="1" placeholder="جمعية" appTrim />
              </mat-form-field>
              @if ( secondName.errors && secondName.touched &&
              secondName.errors['pattern']) {
              <small class="field__alert-message">لا يمكن ادخال حروف مميزة او ارقام</small>
              }
            </div>
          </div>
          <!-- Email -->
          <div class="field field-string">
            <label class="field__label">
              البريد الالكترونى
              <span class="field__label__required">*</span>
            </label>
            <mat-form-field appearance="outline">
              <input class="warning-border" matInput ngModel name="emailContact" #emailContact="ngModel"
                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" minlength="1" placeholder="info@ezoah.com" required
                appTrim />
            </mat-form-field>
            @if( !emailContact?.valid && emailContact.touched &&
            emailContact.errors && (emailContact.errors['required'] ||
            emailContact.errors['validator'])){
            <small class="field__alert-message">هذا الحقل مطلوب</small>
            } @if (emailContact.errors && emailContact.touched &&
            emailContact.errors['pattern']) {
            <small class="field__alert-message">البريد الإلكتروني الذي أدخلته غير صحيح</small>
            }
          </div>
          <!-- Attachments -->
          <div class="field form-files">
            <label class="field__label"> المرفقات </label>
            <!-- attach file  -->
            <div class="form-files__attach" (click)="triggerUploadFiles()" [ngStyle]="{
                display:
                  this.attachmentFiles && this.attachmentFiles.length > 0
                    ? 'none'
                    : 'flex'
              }">
              <mat-icon aria-hidden="false" aria-label="Example attach icon"
                class="attach-btn material-icons-outlined">attach_file</mat-icon>
              <span> إرفق ملف </span>
            </div>
            <!-- file field  -->
            <div class="field field-file">
              <input (change)="uploadFiles($event)" class="warning-border" style="display: none" name="image" required
                #attachmentFilesInput type="file" multiple accept="application/pdf,image/png, image/jpg, image/jpeg" />
            </div>
            <!-- files badges wrapper  -->
            <div class="form-files__files-wrapper">
              @for (file of attachmentFiles; track $index) {
              <div class="file-badge">
                @if (file.type == 'application/pdf') {
                <span>pdf.</span>
                <img loading="lazy" src="../../../../assets/images/attachment-pdf.png" alt="إرفق ملف" />
                } @if (file.type == 'image/png' || file.type == 'image/jpg' ||
                file.type == 'image/jpeg') {
                <span> Image </span>
                <img loading="lazy" src="../../../../assets/images/attachment-img.png" alt="إرفق صورة" />
                }
                <mat-icon (click)="removeFile(file)" aria-hidden="false" aria-label="Example close icon"
                  class="close-btn">close</mat-icon>
              </div>
              } @if (this.attachmentFiles && this.attachmentFiles.length
              < 10) { <img loading="lazy" class="add-btn" [ngStyle]="{
                  display:
                    this.attachmentFiles && this.attachmentFiles.length > 0
                      ? 'block'
                      : 'none'
                }" (click)="triggerUploadFiles()" src="../../../../assets/images/attach.svg"
                alt="إرفق مرفقات للتواصل" />
              }
            </div>
          </div>
          <!-- Message -->
          <div class="field field-string field-text-area">
            <label class="field__label">
              إكتب رسالة
              <span class="field__label__required">*</span>
            </label>
            <mat-form-field appearance="outline">
              <textarea class="warning-border msg-text-area" matInput ngModel name="message" #message="ngModel"
                placeholder="اكتب الرسالة هنا" [TemplateFormValidator]="message" [validator]="validateWhiteSpacesInput"
                required appTrim></textarea>
            </mat-form-field>
            @if( !message?.valid && message.touched && message.errors &&
            (message.errors['required'] || message.errors['validator'])){
            <small class="field__alert-message">هذا الحقل مطلوب</small>
            }
          </div>
          <div class="field contactReason" >
            <!-- <mat-checkbox [(ngModel)]="contactForJob" name="contactForJob">
              التواصل بغرض التوظيف
            </mat-checkbox> -->
            <label>التواصل بغرض:</label>
            <mat-radio-group name="contactReason" matInput [(ngModel)]="contactReason" >
              <mat-radio-button value="jobApplication">التوظيف</mat-radio-button>
              <mat-radio-button value="suggestions">المقترحات</mat-radio-button>
              <mat-radio-button value="complain">الشكاوى</mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- Submit button -->
          <div class="form-action">
            <button mat-button (click)="submitContactUsForm()" [class.disabledSubmit]="
                contactUsForm.status == 'INVALID' || !isSubmitEnabled
              " class="submit-btn" [disabled]="contactUsForm.status == 'INVALID' || !isSubmitEnabled" type="submit"
              mat-button>
              @if(isSubmitEnabled){ ارسال }@else {
              <app-progress-spinner></app-progress-spinner>
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Important number section -->
  <div class="important-numbers d-flex">
    <div class="important-numbers__title">ارقام قد تهمك</div>
    <div class="important-numbers__cards d-flex">
      <!-- card item -->
      <div class="card-item d-flex">
        <div class="phone-icon d-flex">
          <img loading="lazy" src="../../../../assets/images/phone_solid.svg" alt="الإدارة العامة للحماية الأسرية" />
        </div>
        <div class="info d-flex">
          <div class="title">الإدارة العامة للحماية الأسرية</div>
          <div class="number">1919</div>
        </div>
      </div>
      <!-- card item -->
      <div class="card-item d-flex">
        <div class="phone-icon">
          <img loading="lazy" src="../../../../assets/images/phone_solid.svg" alt="خط مساندة الطفل" />
        </div>
        <div class="info d-flex">
          <div class="title">خط مساندة الطفل</div>
          <div class="number">116111</div>
        </div>
      </div>
      <!-- card item -->
      <div class="card-item d-flex">
        <div class="phone-icon">
          <img loading="lazy" src="../../../../assets/images/phone_solid.svg" alt="الرقم الموحد لوزارة العدل" />
        </div>
        <div class="info d-flex">
          <div class="title">الرقم الموحد لوزارة العدل</div>
          <div class="number">1950</div>
        </div>
      </div>
    </div>
  </div>
</div>